import { TelemetryDataReturn } from '@apps/admin/common/IframeTelemetry/IframeTelemetry.types';

const category = 'websiteDesignerV2';
const pagePrefix = 'admin';
const action = 'InlineEditorButtonInteracted';

const actionProps = {
  action,
  category,
  pagePrefix
};

export const telemetryData = {
  inlineEditCTAClick: (label: string, action: 'show' | 'hide'): TelemetryDataReturn => ({
    telemetryType: 'track',
    ...actionProps,
    extraInfo: {
      name: 'inlineEditViewClicked',
      label,
      action
    }
  }),
  inlineActionClicked: (label: string): TelemetryDataReturn => ({
    telemetryType: 'track',
    ...actionProps,
    extraInfo: {
      name: 'inlineEditActionClicked',
      label,
      action
    }
  })
};
