import React, { FC, useCallback, useMemo } from 'react';
import { Font } from '@assets/icons';
import { Flex, TextV2, TooltipV2 } from '@withjoy/joykit';
import { Edit, Photos, Sun } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { useTranslation } from '@shared/core';

export type ActionData = Partial<
  {
    [key in InlineEditorActions]: () => void;
  }
>;

type ActionBarProps = {
  actionData: ActionData;
  actionView?: 'compact' | 'detailed';
  scaleValue: number;
  onActionClick: (action: InlineEditorActions) => void;
};

export enum InlineEditorActions {
  EDIT = 'edit',
  FONT = 'font',
  VISIBILITY = 'visibility',
  EDIT_PHOTO = 'editPhoto'
}

const ACTION_TO_ICON_MAPPER = {
  [InlineEditorActions.EDIT]: Edit,
  [InlineEditorActions.FONT]: Font,
  [InlineEditorActions.VISIBILITY]: Sun,
  [InlineEditorActions.EDIT_PHOTO]: Photos
};

export const ActionBar: FC<ActionBarProps> = props => {
  const { actionData, actionView = 'compact', scaleValue, onActionClick } = props;

  const { t2 } = useTranslation('joykit');
  const editorTrans = t2('inlineEditor');

  const labelTexts = useMemo(
    () => ({
      [InlineEditorActions.EDIT]: editorTrans.content,
      [InlineEditorActions.FONT]: editorTrans.fontStyles,
      [InlineEditorActions.VISIBILITY]: editorTrans.pagePublice,
      [InlineEditorActions.EDIT_PHOTO]: editorTrans.editPhoto
    }),
    [editorTrans]
  );

  const generatedActionList = Object.keys(actionData).map(actionName => ({
    id: actionName as InlineEditorActions,
    icon: ACTION_TO_ICON_MAPPER[actionName as InlineEditorActions],
    onClick: () => {
      onActionClick(actionName as InlineEditorActions);
      actionData[actionName as InlineEditorActions]?.();
    }
  }));

  const applyScaling = useCallback((value: number) => value / scaleValue, [scaleValue]);

  return (
    <Flex position="relative" flexDirection="column" alignItems="center" gap={pxToRem(applyScaling(10))} zIndex="banner">
      <Flex
        flexDirection={actionView === 'compact' ? 'row' : 'column'}
        alignItems={actionView === 'compact' ? 'flex-start' : 'center'}
        gap={actionView === 'compact' ? pxToRem(applyScaling(4)) : pxToRem(applyScaling(16))}
        {...(actionView === 'compact' && {
          padding: pxToRem(applyScaling(8)),
          borderRadius: pxToRem(applyScaling(56)),
          backgroundColor: 'white',
          boxShadow: '0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)'
        })}
        color="mono14"
        pointerEvents="all"
      >
        {generatedActionList.map((action, index) => {
          const Icon = action.icon;
          return actionView === 'compact' ? (
            <TooltipV2
              key={index}
              onMouseEnterDelay={200}
              content={
                <TextV2 typographyVariant="label2" fontSize={pxToRem(applyScaling(13))} textAlign="center" color="mono4">
                  {labelTexts[action.id]}
                </TextV2>
              }
              placement="top"
            >
              <Flex
                width={pxToRem(applyScaling(40))}
                height={pxToRem(applyScaling(40))}
                paddingY={pxToRem(applyScaling(8))}
                alignItems="center"
                justifyContent="center"
                borderRadius={pxToRem(applyScaling(20))}
                _hover={{
                  backgroundColor: 'mono2',
                  color: '#4E06F2'
                }}
                _active={{
                  backgroundColor: 'mono3',
                  color: '#300495'
                }}
                cursor="pointer"
                onClick={action.onClick}
                __css={{ '& > svg': { width: pxToRem(applyScaling(16)), height: pxToRem(applyScaling(16)) } }}
              >
                <Icon size={16} />
              </Flex>
            </TooltipV2>
          ) : (
            <Flex
              key={index}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              height={pxToRem(applyScaling(56))}
              cursor="pointer"
              gap={pxToRem(applyScaling(12))}
              paddingX={pxToRem(applyScaling(32))}
              borderRadius={pxToRem(applyScaling(56))}
              boxShadow="0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)"
              backgroundColor="white"
              color="mono14"
              _hover={{
                backgroundColor: 'mono2',
                color: '#4E06F2'
              }}
              _active={{
                backgroundColor: 'mono3',
                color: '#300495'
              }}
              onClick={action.onClick}
              __css={{ '& > svg': { width: pxToRem(applyScaling(24)), height: pxToRem(applyScaling(24)) } }}
            >
              <Icon size={24} />
              <TextV2 typographyVariant="button2" fontSize={pxToRem(applyScaling(16))} fontFamily="Inter UI" fontWeight={600}>
                {labelTexts[action.id]}
              </TextV2>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

ActionBar.displayName = 'ActionBar';
