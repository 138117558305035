import React, { FC } from 'react';
import { StyledLinearGradient, StyledSvg } from './AnimatedBorder.styles';
import { AnimatedBorderProps } from './AnimatedBorder.types';

export const AnimatedBorder: FC<AnimatedBorderProps> = props => {
  const { isVisibile, inset, padding, borderRadius, borderWidth } = props;

  return (
    <>
      <svg style={{ position: 'absolute' }} width="0" height="0">
        <defs>
          <StyledLinearGradient id="grad" x1="0" x2="1" y1="0" y2="1">
            <stop offset="0%" id="stop1" stopColor="color(display-p3 0.28 0 1 / 1)" />
            <stop offset="25%" id="stop2" stopColor="color(display-p3 0.28 0 1 / 1)" />
            <stop offset="50%" id="stop3" stopColor="color(display-p3 0.28 0 1 / 1)" />
            <stop offset="75%" id="stop4" stopColor="color(display-p3 0.28 0 1 / 1)" />
            <stop offset="100%" id="stop5" stopColor="color(display-p3 0.28 0 1 / 1)" />
          </StyledLinearGradient>
        </defs>

        <symbol id="border" overflow="visible">
          <rect width="100%" height="100%" rx={borderRadius} ry={borderRadius} />
        </symbol>
      </svg>

      <StyledSvg isVisibile={isVisibile} inset={inset} padding={padding} borderWidth={borderWidth}>
        <use href="#border" />
      </StyledSvg>
    </>
  );
};

AnimatedBorder.displayName = 'AnimatedBorder';
