import { styled } from '@withjoy/joykit';

export const StyledLinearGradient = styled.linearGradient`
  #stop1,
  #stop2,
  #stop3,
  #stop4,
  #stop5 {
    animation: animate-gradient 6s ease infinite;
  }

  #stop1 {
    animation-delay: 0ms;
  }
  #stop2 {
    animation-delay: 500ms;
  }
  #stop3 {
    animation-delay: 1000ms;
  }
  #stop4 {
    animation-delay: 1500ms;
  }
  #stop5 {
    animation-delay: 2500ms;
  }

  @keyframes animate-gradient {
    0% {
      stop-color: color(display-p3 0.28 0 1 / 1);
    }
    12.5% {
      stop-color: color(display-p3 0.385 0 1 / 1);
    }
    25% {
      stop-color: color(display-p3 0.647 0 1 / 1);
    }
    37.5% {
      stop-color: color(display-p3 0.385 0 1 / 1);
    }
    50% {
      stop-color: color(display-p3 0.28 0 1 / 1);
    }
    62.5% {
      stop-color: color(display-p3 0.385 0 1 / 1);
    }
    75% {
      stop-color: color(display-p3 0.647 0 1 / 1);
    }
    87.5% {
      stop-color: color(display-p3 0.385 0 1 / 1);
    }
    100% {
      stop-color: color(display-p3 0.28 0 1 / 1);
    }
  }
`;

export const StyledSvg = styled.svg<{ isVisibile: boolean; inset: string; padding: string | number; borderWidth: string }>`
  display: ${({ isVisibile }) => (isVisibile ? 'block' : 'none')};
  z-index: 10;
  position: absolute;
  overflow: visible;
  width: ${({ inset }) => `calc(100% - calc(2 * ${inset}))`};
  height: ${({ inset }) => `calc(100% - calc(2 * ${inset}))`};
  inset: ${({ inset }) => inset};
  padding: ${({ padding }) => padding};
  fill: none;
  stroke: url(#grad);
  stroke-width: ${({ borderWidth }) => borderWidth};
  animation: animate-gradient 6s ease infinite; // hack to run the animation in safari
`;
